import axios from "../config/axios"
import _ from "lodash"
import getConfig from "next/config"


const instance = axios.create({
    baseURL:
        getConfig().publicRuntimeConfig.externalAPIEndpoint + "/api",
    timeout: 30000
})

export const modelTransactionsFetch = async (
    type,
    state = null,
    location = null,
    filters = {},
    previous = null,
    next = null,
    from = null
) => {
    const transactions = await instance.post("/transactions/fetch", {
        type,
        state,
        location,
        filters,
        previous,
        next,
        from
    })

    return transactions.data
}

export const modelTransactionsCountFetch = async (
    type,
    state = null,
    location = null,
    filters = {}
) => {
    const transactionsCount = await instance.post("/transactions/count", {
        type,
        state,
        location,
        filters
    })

    return transactionsCount.data
}