import {
    PROSPECTION_FETCH,
    PROSPECTION_FETCHED,
    PROSPECTION_ERROR,
    PROSPECTION_RESET,
    SIMILAR_PROSPECTIONS_FETCH,
    SIMILAR_PROSPECTIONS_FETCHED,
    SET_PROSPECTION_CURRENT_PARAMETERS,
    SIMILAR_PROSPECTIONS_ERROR,
    POPULATED_CITIES_FETCH,
    POPULATED_CITIES_FETCHED,
    POPULATED_CITIES_ERROR,
    PROSPECTION_MARK_CHANGED,
    PROSPECTION_MARK_VALIDATE,
    PROSPECTION_MARK_VALIDATED,
    PROSPECTION_MARK_ERROR,
    PROSPECTION_MARK_FETCHED,
    LAST_PROSPECTIONS_FETCHED,
    LAST_PROSPECTIONS_ERROR,
    AROUND_LOCATION_CITIES_FETCH,
    AROUND_LOCATION_CITIES_FETCHED
} from './types'

import reverseCodeRegions from '../datas/reverseCodeRegions'
import {
    modelProspectionPublicFetch,
    modelProspectionsStateFetch,
    modelProspectionPublicFetchByReferenceId,
    modelProspectionMarkFetch,
    modelProspectionMarkValidate
} from '../models/Prospections'
import { modelGetProspectionDescription } from '../models/MessagesTemplates'
import {
    modelGetMostPopulatedCitiesV2,
    modelGetAroundLocationCities
} from '../models/Locations'
import { modelCitiesPublicPageInsees } from '../models/PublicPages'
import { translateLocationsToAlgoliaFilters } from '../helpers/Locations'

export const prospectionMarkChanged = mark => {
    return {
        type: PROSPECTION_MARK_CHANGED,
        payload: mark
    }
}

export const prospectionMarkValidate = (id, mark) => {
    return async dispatch => {
        dispatch({
            type: PROSPECTION_MARK_VALIDATE
        })

        try {
            const res = await modelProspectionMarkValidate(id, mark)

            dispatch({
                type: PROSPECTION_MARK_VALIDATED,
                payload: res
            })
        } catch (e) {
            dispatch({
                type: PROSPECTION_MARK_ERROR,
                payload: e
            })
        }
    }
}

export const asyncProspectionMarkFetch = async (
    id,
    runtimeConfig = null,
    serverInstance = null
) => {
    const mark = await modelProspectionMarkFetch(
        id,
        runtimeConfig,
        serverInstance
    )

    return { type: PROSPECTION_MARK_FETCHED, payload: mark }
}

export const prospectionFetch = (
    type,
    prospectionId,
    selectedLocationId,
    runtimeConfig = null,
    serverInstance = null
) => {
    return dispatch => {
        dispatch({ type: PROSPECTION_FETCH })

        modelProspectionPublicFetch(
            type,
            prospectionId,
            runtimeConfig,
            serverInstance
        )
            .then(result => {
                modelGetProspectionDescription(
                    prospectionId,
                    selectedLocationId,
                    result,
                    runtimeConfig,
                    serverInstance
                ).then(description => {
                    dispatch({
                        type: PROSPECTION_FETCHED,
                        payload: { ...result, description },
                        ssr: false
                    })
                })
            })
            .catch(error => {
                console.log('Error prospection fetch: ', error)
            })
    }
}

export const asyncProspectionPublicFetch = async (
    type,
    prospectionId,
    selectedLocationId,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const result = await modelProspectionPublicFetch(
            type,
            prospectionId,
            runtimeConfig,
            serverInstance
        )
        const description = await modelGetProspectionDescription(
            prospectionId,
            selectedLocationId,
            result,
            runtimeConfig,
            serverInstance
        )
        return {
            type: PROSPECTION_FETCHED,
            payload: { ...result, description },
            ssr: true
        }
    } catch (error) {
        console.log('Error prospection fetch: ', error)
        return {
            type: PROSPECTION_ERROR,
            payload: error,
            ssr: true
        }
    }
}

export const prospectionFetchByReferenceId = (
    type,
    referenceId,
    selectedLocationPosition,
    runtimeConfig = null,
    serverInstance = null
) => {
    return async dispatch => {
        dispatch({ type: PROSPECTION_FETCH })
        try {
            const result = await modelProspectionPublicFetchByReferenceId(
                type,
                referenceId,
                runtimeConfig,
                serverInstance
            )
            let selectedLocationId =
                result.locations[selectedLocationPosition].id
            const description = await modelGetProspectionDescription(
                result.prospection_id,
                selectedLocationId,
                result,
                runtimeConfig,
                serverInstance
            )
            dispatch({
                type: PROSPECTION_FETCHED,
                payload: { ...result, description }
            })
        } catch (error) {
            console.log('Error prospection fetch: ', error)
            dispatch({
                type: PROSPECTION_ERROR,
                payload: error
            })
            throw error
        }
    }
}

export const asyncProspectionFetchByReferenceId = async (
    type,
    referenceId,
    selectedLocationPosition,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const result = await modelProspectionPublicFetchByReferenceId(
            type,
            referenceId,
            runtimeConfig,
            serverInstance
        )
        if (typeof result.locations[selectedLocationPosition] === 'undefined') {
            throw 'unknown_location_position'
        }
        let selectedLocationId = result.locations[selectedLocationPosition].id
        const description = await modelGetProspectionDescription(
            result.prospection_id,
            selectedLocationId,
            result,
            runtimeConfig,
            serverInstance
        )
        return {
            type: PROSPECTION_FETCHED,
            payload: { ...result, description },
            ssr: true
        }
    } catch (error) {
        console.log('Error prospection fetch: ', error)
        throw error
        /* return {
            type: PROSPECTION_ERROR,
            payload: error,
            ssr: true
        } */
    }
}

export const asyncProspectionFetchByReferenceIdV2 = async (
    type,
    referenceId,
    selectedLocationPosition,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        let result = await modelProspectionPublicFetchByReferenceId(
            type,
            referenceId,
            runtimeConfig,
            serverInstance
        )
        if (typeof result.locations[selectedLocationPosition] === 'undefined') {
            throw 'unknown_location_position'
        }
        let selectedLocationId = result.locations[selectedLocationPosition].id
        const description = await modelGetProspectionDescription(
            result.prospection_id,
            selectedLocationId,
            result,
            runtimeConfig,
            serverInstance
        )
        if (serverInstance && serverInstance.citiesInsees) {
            result = {
                ...result,
                available_locations: result
                    .locations
                    .map(l => {
                        if (
                            (
                                ['commune', 'commune_all'].indexOf(l.type) !== -1 &&
                                serverInstance.citiesInsees.indexOf(l.code) !== -1
                            ) ||
                            ['commune', 'commune_all'].indexOf(l.type) === -1
                        ) {
                            return true
                        }
                        return false
                    })
            }
        }
        return {
            type: PROSPECTION_FETCHED,
            payload: { ...result, description },
            ssr: true
        }
    } catch (error) {
        console.log('Error prospection fetch: ', error)
        throw error
        /* return {
            type: PROSPECTION_ERROR,
            payload: error,
            ssr: true
        } */
    }
}

export const setProspectionError = error => {
    return {
        type: PROSPECTION_ERROR,
        payload: error,
        ssr: true
    }
}

export const asyncSimilarProspectionsFetch = async (
    type,
    id,
    location,
    prospection,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false
) => {
    try {
        /* if (prospection !== null) {
            optionalFilters.push(locations.join(", "))
            optionalFilters.push(
                "prospection.activity_type: " +
                    prospection.userinfo.activityBrands
            )
        } */

        let results = await modelProspectionsStateFetch(
            'firestore',
            reverseCodeRegions[location.codeRegion],
            [location],
            {
                type,
                exclude: [`${id}_${location.id}`]
            },
            0,
            3,
            false,
            runtimeConfig,
            serverInstance,
            forceAPI
        )
        let result = results[0]

        return {
            type: SIMILAR_PROSPECTIONS_FETCHED,
            payload: result[0],
            ssr: true
        }
    } catch (error) {
        console.log('Error similar prospections list: ', error)
        return {
            type: SIMILAR_PROSPECTIONS_ERROR,
            payload: error,
            ssr: true
        }
    }
}

export const asyncSimilarProspectionsFetchByReferenceId = async (
    type,
    referenceId,
    location,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false
) => {
    try {
        let locations = await translateLocationsToAlgoliaFilters([location])
        let typeFilter = []
        let optionalFilters = []

        /* if (prospection !== null) {
            optionalFilters.push(locations.join(", "))
            optionalFilters.push(
                "prospection.activity_type: " +
                    prospection.userinfo.activityBrands
            )
        } */

        let results = await modelProspectionsStateFetch(
            'algolia',
            reverseCodeRegions[location.codeRegion],
            locations,
            { type, typeFilter, excludeReferences: [referenceId], optionalFilters, hideAll: true },
            0,
            3,
            false,
            runtimeConfig,
            serverInstance,
            forceAPI
        )
        let result = results[0]

        return {
            type: SIMILAR_PROSPECTIONS_FETCHED,
            payload: result[0],
            ssr: true
        }
    } catch (error) {
        console.log('Error similar prospections list: ', error)
        return {
            type: SIMILAR_PROSPECTIONS_ERROR,
            payload: error,
            ssr: true
        }
    }
}

export const asyncSimilarProspectionsFetchByReferenceIdV2 = async (
    type,
    referenceId,
    location,
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false
) => {
    try {
        let locations = await translateLocationsToAlgoliaFilters([location])
        let typeFilter = []
        let optionalFilters = []

        /* if (prospection !== null) {
            optionalFilters.push(locations.join(", "))
            optionalFilters.push(
                "prospection.activity_type: " +
                    prospection.userinfo.activityBrands
            )
        } */

        let results = await modelProspectionsStateFetch(
            'algolia',
            reverseCodeRegions[location.codeRegion],
            locations,
            { type, typeFilter, excludeReferences: [referenceId], optionalFilters, hideAll: true },
            0,
            3,
            false,
            runtimeConfig,
            serverInstance,
            forceAPI
        )
        let result = results[0]

        if (serverInstance && serverInstance.citiesInsees) {
            for (let i = 0; i < result[0].length; i++) {
                result[0][i] = {
                    ...result[0][i],
                    available_locations: result[0][i]
                        .locations
                        .map(l => {
                            if (
                                (
                                    ['commune', 'commune_all'].indexOf(l.type) !== -1 &&
                                    serverInstance.citiesInsees.indexOf(l.code) !== -1
                                ) ||
                                ['commune', 'commune_all'].indexOf(l.type) === -1
                            ) {
                                return true
                            }
                            return false
                    })
                }
            }
        }

        return {
            type: SIMILAR_PROSPECTIONS_FETCHED,
            payload: result[0],
            ssr: true
        }
    } catch (error) {
        console.log('Error similar prospections list: ', error)
        return {
            type: SIMILAR_PROSPECTIONS_ERROR,
            payload: error,
            ssr: true
        }
    }
}

export const asyncPopulatedCitiesFetch = async (
    type,
    state,
    locations = [],
    limit = 20,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const result = await modelGetMostPopulatedCities(
            type,
            state,
            locations,
            limit,
            runtimeConfig,
            serverInstance
        )

        return {
            type: POPULATED_CITIES_FETCHED,
            payload: result,
            state,
            entityType: type,
            locations,
            ssr: true
        }
    } catch (error) {
        console.log('Populated cities list: ', error)
        return {
            type: POPULATED_CITIES_ERROR,
            payload: error,
            ssr: true
        }
    }
}

export const asyncPopulatedCitiesFetchV2 = async (
    type,
    version = 1,
    state,
    locations = [],
    limit = 20,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        let ps = []
        ps.push(
            modelGetMostPopulatedCitiesV2(
                state,
                locations,
                limit,
                true,
                runtimeConfig,
                serverInstance
            )
        )
        if (!serverInstance || (serverInstance && !serverInstance.citiesInsees)) {
            ps.push(
                modelCitiesPublicPageInsees(
                    type,
                    version,
                    runtimeConfig,
                    serverInstance
                )
            )
        }
        let results = await Promise.all(ps)
        let result = results[0]
        let insees = null
        if (!serverInstance || (serverInstance && !serverInstance.citiesInsees)) {
            insees = results[1]
        } else {
            insees = serverInstance.citiesInsees
        }

        result = {
            ...result,
            locations: result
                .locations
                .filter(o => insees.indexOf(o.code) !== -1)
        }

        return {
            type: POPULATED_CITIES_FETCHED,
            payload: result,
            state,
            entityType: type,
            locations,
            ssr: true
        }
    } catch (error) {
        console.log('Populated cities list: ', error)
        return {
            type: POPULATED_CITIES_ERROR,
            payload: error,
            ssr: true
        }
    }
}

export const asyncAroundLocationCitiesFetch = async (
    state,
    location,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        const result = await modelGetAroundLocationCities(
            state,
            location,
            true,
            runtimeConfig,
            serverInstance
        )

        return {
            type: AROUND_LOCATION_CITIES_FETCHED,
            payload: result,
            state,
            location,
            ssr: true
        }
    } catch (error) {
        console.log('Around Location cities list: ', error)
    }
}

export const asyncAroundLocationCitiesFetchV2 = async (
    type,
    version = 1,
    state,
    location,
    runtimeConfig = null,
    serverInstance = null
) => {
    try {
        let ps = []
        ps.push(
            modelGetAroundLocationCities(
                state,
                location,
                true,
                runtimeConfig,
                serverInstance
            )
        )
        if (!serverInstance || (serverInstance && !serverInstance.citiesInsees)) {
            ps.push(
                modelCitiesPublicPageInsees(
                    type,
                    version,
                    runtimeConfig,
                    serverInstance
                )
            )
        }
        let results = await Promise.all(ps)
        let result = results[0]
        let insees = null
        if (!serverInstance || (serverInstance && !serverInstance.citiesInsees)) {
            insees = results[1]
        } else {
            insees = serverInstance.citiesInsees
        }
        result = {
            ...result,
            locations: result
                .locations
                .filter(o => insees.indexOf(o.code) !== -1)
        }

        return {
            type: AROUND_LOCATION_CITIES_FETCHED,
            payload: result,
            state,
            location,
            ssr: true
        }
    } catch (error) {
        console.log('Around Location cities list: ', error)
    }
}

export const asyncLastProspectionsFetch = async (
    runtimeConfig = null,
    serverInstance = null,
    forceAPI = false,
    refreshCache = false
) => {
    try {
        const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
        const redis = redisInst
        const redisKey = 'getHomepageLastProspections'
        // console.log('REDIS KEY: ', redisKey)

        if (refreshCache) {
            await redis.del(redisKey)
        }

        try {
            const res = await redis.get(redisKey)
            // console.log('REDIS GET: ', redisKey, res)

            if (res === null) {
                throw 'doesnt_exist'
            }

            return {
                type: LAST_PROSPECTIONS_FETCHED,
                payload: JSON.parse(res),
                ssr: true
            }
        } catch (error) {
            // console.log('REDIS ERROR:', error)
        }

        const results = await modelProspectionsStateFetch(
            'firestore',
            'all',
            [],
            {
                type: ['local-commercial', 'fonds-commerce', 'terrain']
            },
            0,
            3,
            false,
            runtimeConfig,
            serverInstance,
            forceAPI
        )
        let result = results[0]

        redis.set(redisKey, JSON.stringify(result[0]), 'EX', 60 * 60 * 24).then(res => {
            // console.log('REDIS SET: ', res)
        })

        return {
            type: LAST_PROSPECTIONS_FETCHED,
            payload: result[0],
            ssr: true
        }
    } catch (error) {
        console.log('Error last prospections list: ', error)
        return {
            type: LAST_PROSPECTIONS_ERROR,
            payload: error,
            ssr: true
        }
    }
}

export const lastProspectionsFetch = async (
    runtimeConfig = null,
    serverInstance = null
) => {
    return async dispatch => {
        dispatch({
            type: LAST_PROSPECTIONS_FETCH
        })

        try {
            const redisInst = serverInstance && serverInstance.redis ? serverInstance.redis : null
            const redis = redisInst
            const redisKey = 'getHomepageLastProspections'
            // console.log('REDIS KEY: ', redisKey)

            if (refreshCache) {
                await redis.del(redisKey)
            }

            try {
                const res = await redis.get(redisKey)
                // console.log('REDIS GET: ', redisKey, res)

                if (res === null) {
                    throw 'doesnt_exist'
                }

                return dispatch({
                    type: LAST_PROSPECTIONS_FETCHED,
                    payload: JSON.parse(res),
                    ssr: false
                })
            } catch (error) {
                // console.log('REDIS ERROR:', error)
            }

            let results = await modelProspectionsStateFetch(
                "firestore",
                "all",
                [],
                {
                    type: ['local-commercial', 'fonds-commerce', 'terrain']
                },
                0,
                3,
                false,
                runtimeConfig,
                serverInstance,
                true
            )
            let result = results[0]

            redis.set(redisKey, JSON.stringify(result[0]), 'EX', 60 * 60 * 24).then(res => {
                // console.log('REDIS SET: ', res)
            })

            dispatch({
                type: LAST_PROSPECTIONS_FETCHED,
                payload: result[0],
                ssr: false
            })
        } catch (error) {
            console.log("Error last prospections list: ", error)
            dispatch({
                type: LAST_PROSPECTIONS_ERROR,
                payload: error,
                ssr: false
            })
        }
    }
}

export const setProspectionCurrentParameters = (
    originParameters,
    currentParameters
) => {
    return {
        type: SET_PROSPECTION_CURRENT_PARAMETERS,
        payload: { originParameters, currentParameters }
    }
}

export const prospectionReset = () => {
    return {
        type: PROSPECTION_RESET
    }
}
