import {
    AGENCY_FETCH,
    AGENCY_FETCHED,
    AGENCY_RESET,
    AGENCY_ERROR,
    AGENCY_PROFILE_FETCH,
    AGENCY_PROFILE_FETCHED,
    AGENCY_PROFILE_RESET,
    AGENCY_PROFILE_ERROR
} from "./types"

import {
    modelAgencyFetch,
    modelAgencyProfileGetByURLId,
    modelAgencyMemberFetchByURLId,
    modelAgencyStatsGetByURLId,
    modelAgencyTransactionsFetchMapByURLId,
    modelAgencyTransactionsFetchByURLId,
} from "../models/Agencies"

/* libelleState,
[location].filter(o => o),
{
    type,
    transaction,
    activity,
    place
},
{
    firebase: req.firebaseServer,
    firebase_second: req.firebaseServerSecond,
    redis: req.redis
} */

export const agenciesFetch = (
    state,
    locations,
    filters,
    page = 0,
    hitsPerPage = 20,
    runtimeConfig = {}
) => {
    return async dispatch => {
        dispatch({ type: AGENCY_FETCH })
        try {
            const content = await modelAgencyFetch(
                state,
                locations,
                filters,
                page,
                hitsPerPage,
                runtimeConfig
            )
            dispatch({
                type: AGENCY_FETCHED,
                state,
                locations,
                filters,
                payload: content
            })
        } catch (error) {
            console.log("error: ", error)
            dispatch({ type: AGENCY_ERROR, payload: error })
        }
    }
}

export const asyncAgenciesFetch = async (
    state,
    locations,
    filters,
    page = 0,
    hitsPerPage = 20,
    runtimeConfig = {}
) => {
    try {
        const content = await modelAgencyFetch(
            state,
            locations,
            filters,
            page,
            hitsPerPage,
            runtimeConfig
        )
        return {
            type: AGENCY_FETCHED,
            state,
            locations,
            filters,
            payload: content
        }
    } catch (error) {
        console.log("error: ", error)
        return { type: AGENCY_ERROR, payload: error }
    }
}

export const agenciesReset = () => {
    return { type: AGENCY_RESET }
}

export const agencyProfileGetByURLId = (
    URLId,
    refreshCache = false,
    runtimeConfig = {},
    serverInstance = null
) => {
    return async dispatch => {
        dispatch({ type: AGENCY_PROFILE_FETCH })
        try {
            const infos = await modelAgencyProfileGetByURLId(
                URLId,
                refreshCache,
                runtimeConfig,
                serverInstance
            )
            dispatch({
                type: AGENCY_PROFILE_FETCHED,
                payload: infos
            })
        } catch (error) {
            console.log("agencyProfileGetByURLId error: ", error)
            dispatch({ type: AGENCY_PROFILE_ERROR, payload: error })
        }
    }
}

export const asyncAgencyProfileGetByURLId = async (
    URLId,
    previous = null,
    next = null,
    refreshCache = false,
    runtimeConfig = {},
    serverInstance = null
) => {
    try {
        const infos = await Promise.all([
            modelAgencyProfileGetByURLId(
                URLId,
                refreshCache,
                runtimeConfig,
                serverInstance
            ),
            modelAgencyMemberFetchByURLId(
                URLId,
                refreshCache,
                runtimeConfig,
                serverInstance
            ),
            modelAgencyStatsGetByURLId(
                URLId,
                refreshCache,
                runtimeConfig
            ),
            modelAgencyTransactionsFetchMapByURLId(
                URLId,
                refreshCache,
                runtimeConfig
            ),
            modelAgencyTransactionsFetchByURLId(
                URLId,
                null,
                null,
                {},
                previous,
                next,
                refreshCache,
                runtimeConfig,
                'agency_profile'
            )
        ])
        return {
            type: AGENCY_PROFILE_FETCHED,
            payload: infos
        }
    } catch (error) {
        console.log("asyncAgencyProfileGetByURLId error: ", error)
        return { type: AGENCY_PROFILE_ERROR, payload: error }
    }
}

export const agencyProfileReset = () => {
    return { type: AGENCY_PROFILE_RESET }
}